.publisher {
  button {
    width: 100%;
  }
  .italic {
    font-style: italic;
  }
 .img-responsive {
    max-width: 150px;
    margin: 0 auto;
}
}
